import React, { useEffect, useState } from "react";
import styled from "styled-components";
import individual from "../img/individual.png";
import union from "../img/union.png";
import { device } from "../hooks/device";
import useScrollLockThree from "../hooks/useScrollLockThree";

// img
import greyX from "../img/delete.png";
import { ModalBackTwo } from "../components/Style";
import { bannerOn } from "../atoms";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import TextLine from "../atomic/atom/TextLine";
import DivTwoPartImprove from "../atomic/atom/DivTwoPartImprove";

interface SignupChooseProps {
  setSignUpChooseModal: React.Dispatch<React.SetStateAction<boolean>>;
  setSignUpInputModal: React.Dispatch<React.SetStateAction<boolean>>;
  setType: React.Dispatch<React.SetStateAction<string>>;
}

interface SignupChooseStyle {
  banneris: boolean;
}

const SignUpChoose: React.FC<SignupChooseProps> = ({
  setSignUpChooseModal,
  setSignUpInputModal,
  setType,
}) => {
  // 뒤로 가기시 모달 꺼주기

  const { t } = useTranslation();
  const baseLang = "login.e1";
  const eventOn = false;

  const goBack = () => {
    setSignUpChooseModal(false);
  };
  const banneris = useRecoilValue(bannerOn);

  useEffect(() => {
    window.addEventListener("popstate", goBack);
    window.history.pushState(null, "", window.location.href);
    return () => {
      window.removeEventListener("popstate", goBack);
    };
  }, []);

  useScrollLockThree();

  return (
    <>
      <ModalBackTwo />
      <StSignUpChoose banneris={banneris}>
        <img
          className="onlyForPc cancelButton"
          src={greyX}
          style={{ cursor: "pointer" }}
          onClick={() => {
            setSignUpChooseModal(false);
          }}
        />
        <div className="container">
          <div className="midTitle">
            <TextLine text={t(`${baseLang}.headerOne`)} />
            <br />
            <TextLine text={t(`${baseLang}.headerTwo`)} />
          </div>
          <div className="midDescribe">
            <TextLine text={t(`${baseLang}.midHeader`)} />
          </div>
          <div
            className="box"
            onClick={() => {
              setType("individual");
              setSignUpInputModal(true);
              setSignUpChooseModal(false);
            }}
          >
            {eventOn && <div className="eventIcon">Promotion</div>}
            <img src={individual} />
            <div className="who">{t(`${baseLang}.planOne.header`)}</div>
            <div className="whoDescribe">
              {t(`${baseLang}.planOne.bodyOne`)} <br />
              {t(`${baseLang}.planOne.bodyTwo`)}
            </div>
            <DivTwoPartImprove
              display="grid"
              align_items="center"
              justify="center"
              font_color="#2276dc"
              lineheight={18}
              font_size={10}
              margin="24px 0 0"
            >
              <TextLine
                text={t(`${baseLang}.planOne.tagOne`)}
                margin="0 auto"
              />
              <TextLine
                text={t(`${baseLang}.planOne.tagTwo`)}
                margin="4px auto 0"
              />
            </DivTwoPartImprove>
          </div>
          <div
            className="box"
            onClick={() => {
              setType("enterprise");
              setSignUpInputModal(true);
              setSignUpChooseModal(false);
            }}
          >
            {eventOn && <div className="eventIcon">Promotion</div>}
            <img src={union} />
            <div className="who">{t(`${baseLang}.planTwo.header`)}</div>
            <div className="whoDescribe">
              {t(`${baseLang}.planTwo.bodyOne`)}
              <br />
              {t(`${baseLang}.planTwo.bodyTwo`)}
            </div>
            <DivTwoPartImprove
              display="grid"
              align_items="center"
              justify="center"
              font_color="#2276dc"
              lineheight={18}
              font_size={10}
              margin="24px 0 0"
            >
              <DivTwoPartImprove display="flex">
                <TextLine text={t(`${baseLang}.planTwo.tagOne`)} />
                <TextLine
                  text={t(`${baseLang}.planTwo.tagTwo`)}
                  margin="0 0 0 14px"
                />
              </DivTwoPartImprove>
              <TextLine
                text={t(`${baseLang}.planTwo.tagThree`)}
                margin="4px auto 0"
              />
            </DivTwoPartImprove>
          </div>
        </div>
      </StSignUpChoose>
    </>
  );
};

const StSignUpChoose = styled.div<SignupChooseStyle>`
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: white;
  @media ${device.pc} {
    z-index: 53;
    left: 50%;
    top: 50%;
    padding: 60px;
    box-sizing: border-box;
    width: 580px;
    transform: translate(-50%, -50%);
    .cancelButton {
      width: 24px;
      height: 24px;
      margin: 8.5px 0 8.5px auto;
    }
    .container {
      padding: 0 74px;
      .midTitle {
        font-size: 26px;
        font-family: "bold";
        line-height: 30px;
      }
      .midDescribe {
        margin: 16px 0 40px;
      }
      .box {
        border: 1px solid #d9d9d9;
        width: 312px;
        margin: 0 auto;
      }
    }
  }
  @media ${device.tabMob} {
    z-index: 50;
    left: 0;
    width: 100%;
    min-width: 360px;
    height: ${(props) =>
      props.banneris ? "calc(100% - 108px)" : "calc(100% - 60px)"};
    bottom: 0px;
    box-sizing: border-box;
    background-color: #f0f1f2;
    overflow: scroll;
    .onlyForPc {
      display: none;
    }
    .container {
      padding: 30px 24px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      overflow: scroll;
      ::-webkit-scrollbar {
        width: 2px;
        height: 10px;
        margin-top: 100px;
      }
      ::-webkit-scrollbar-thumb {
        background-color: #c6c6c6;
        border-radius: 2px;
        width: 2px;
        height: 100px;
      }
      ::-webkit-scrollbar-track {
        border-radius: 1rem;
      }
      .midTitle {
        font-size: 20px;
        font-family: "bold";
        line-height: 30px;
      }
      .midDescribe {
        margin: 3px 0 27px;
      }
      .box {
        box-shadow: 0px 7px 6px rgba(0, 0, 0, 0.08);
        width: 100%;
        max-width: 424px;
      }
    }
  }
  .container {
    width: 100%;
    /* height: 100%; */
    box-sizing: border-box;
    .midTitle {
      text-align: center;
      color: #2276dc;
    }
    .midDescribe {
      font-size: 14px;
      font-family: "medium";
      line-height: 21px;
      text-align: center;
    }
    .box {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /* height: 228px; */
      background: #ffffff;
      border-radius: 24px;
      padding: 34px 24px;
      box-sizing: border-box;
      margin-bottom: 18px;
      cursor: pointer;
      img {
        width: 32px;
        height: 32px;
      }
      .who {
        margin-top: 10px;
        font-size: 24px;
        font-family: "bold";
        line-height: 36px;
      }
      .whoDescribe {
        font-size: 12px;
        line-height: 18px;
        color: #818181;
        margin-top: 14px;
        text-align: center;
      }
      .whoBlue {
        display: grid;
        grid-template-columns: repeat(3, auto);
        font-size: 10px;
        gap: 14px;
        line-height: 18px;
        color: #2276dc;
        margin-top: 14px;
      }
      .eventIcon {
        position: absolute;
        right: 16px;
        top: 16px;
        background-color: #ed893e;
        padding: 3px 12px;
        border-radius: 11px;
        color: white;
        font-size: 10px;
        line-height: 15px;
        font-family: "bold";
      }
    }
  }
`;

export default SignUpChoose;
